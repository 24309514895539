import React from "react"
import Bold from "../components/Bold"
import Contact from "../components/contact-slice"
import Header from "../components/header"
import Layout from "../components/layout"
import ResultSection from "../components/ResultSection"
import SEO from "../components/seo"
import Picture from "../images/blog-post.jpg"
import mihinMedioihinLuotetaanImg from "../images/some-ja-nuoret/mihin-luotetaan.png"
import miksiImg from "../images/some-ja-nuoret/miksi-nuoret-käyttävät.png"
import mitäSomessaTehdäänImg from "../images/some-ja-nuoret/mitä-somessa-tehdään.png"
import someTuonutElämäänImg from "../images/some-ja-nuoret/some-tuo-elämään.png"
import top20Img from "../images/some-ja-nuoret/top-20-somet.png"

export default () => (
  <Layout>
    <SEO
      title={"Some ja nuoret -tutkimus"}
      description={
        "Some ja nuoret -katsaus on koko Suomen kattava verkkokysely suomalaisten nuorten ja nuorten aikuisten sosiaalisen median käytöstä."
      }
      image={Picture}
    />
    <Header siteTitle={"Some ja nuoret -katsaus"} />
    {/* Intro */}
    <section className="slice slice-lg pb-0 pb-lg-5">
      <div className={"container d-flex justify-content-center text-center"}>
        <div className={"col-md-8"}>
          <h2>Katsaus nuorten sosiaalisen median käyttöön</h2>
          <p className={"lead"}>
            Some ja nuoret -katsaus on koko Suomen kattava verkkokysely
            suomalaisten nuorten ja nuorten aikuisten sosiaalisen median
            käytöstä. Kyselyn vastaajina olivat{" "}
            <Bold>13-29 vuotiaat Suomessa asuvat nuoret</Bold>. Tutkimus on
            tehty yhteistyössä Oulun kaupungin sivistys- ja kulttuuripalveluiden
            kanssa. Vuonna 2022 kyselyyn vastasi <Bold>2653</Bold> nuorta.
            Vuoden 2022 tulokset näet{" "}
            <a
              href={"https://wordpress.ebrand.fi/somejanuoret2022"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              tästä.
            </a>{" "}
            Vuonna 2019 kyselyyn osallistui yhteensä <Bold>6247</Bold> vastaajaa
            eri puolilta Suomea. Tutkimus on tehty myös vuosina
            <a
              href={"https://wordpress.ebrand.fi/somejanuoret2019"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              2019
            </a>
            ,
            <a
              href={"https://wordpress.ebrand.fi/somejanuoret2016"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              2016
            </a>
            ,
            <a
              href={"https://wordpress.ebrand.fi/somejanuoret2015"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              2015
            </a>{" "}
            ja
            <a
              href={"https://wordpress.ebrand.fi/somejanuoret2013"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              2013
            </a>
            .
          </p>
        </div>
      </div>
    </section>
    {/* Tulosten esittely alueittain */}
    <section className="section-process slice-lg pt-0">
      <ResultSection
        imageSrc={miksiImg}
        imageAlt="Miksi nuoret käyttävät somea?"
        title="Miksi nuoret käyttävät somea?"
        order={1}
      >
        Kyselyn mukaan lähes 88 prosenttia
        <Bold> 18-22 -vuotiaista nuorista käyttää </Bold>
        Internettiä <Bold>yli 20 tuntia viikossa</Bold>. Internetin käyttö
        ajallisesti on 18-22 ja 23-29 ikäryhmien välillä hyvin vastaavanlainen.
        23-29 –vuotiaiden nuorten osalta luku on 86 %. Internetin suurkuluttajia
        löytyy jonkin verran enemmän vanhemmista ikäryhmistä: 13-17 -vuotiaista
        noin 17 % käyttää Internetiä yli 51 tuntia viikossa. 18 – 22 v
        vastaajista taas jopa 32 %. 23-29 –vuotiaista 29 % viettää Internetissä
        kyseisen ajan viikon aikana.
        <Bold> Netin käyttö tunneissa näyttää lisääntyneen </Bold>koko
        vastaajajoukossa.
      </ResultSection>

      <ResultSection
        imageSrc={top20Img}
        imageAlt="Suosituimmat some-palvelut"
        title="Suosituimmat sosiaalisen median palvelut"
        order={2}
      >
        Kyselyn mukaan suomalaisnuorten suosituimmat sosiaalisen median palvelut
        ovat{" "}
        <Bold>
          WhatsApp, YouTube, Instagram, Spotify, Snapchat, TikTok, Facebook,
          Discord, Pinterest ja Jodel
        </Bold>
        . Kyselyyn vastanneista peräti 93 % käyttää WhatsAppia, YouTubea 88 % ja
        Instagramia 82 %. Spotify on neljäs 80 % käyttöasteella. Snapchatia
        käyttää 75 % vastaajista. Tämän jälkeen palveluiden suosio selvästi
        hajaantuu.
      </ResultSection>

      <ResultSection
        imageSrc={mitäSomessaTehdäänImg}
        imageAlt="Mitä somessa tehdään?"
        title="Mitä somessa tehdään?"
        order={1}
      >
        Hyvin yleistä on reaaliaikaisen keskustelun käyminen
        pikakeskustelupalveluiden tai chattien kautta. Pikaviestintä on jo
        vuosia ollut osa nuorten elämää. Suosituin pikaviestintäalusta, WhatsApp
        (93 % vastaajista) sai vuoden 2013 kyselyssä 8 % kannatuksen. Vuonna
        2015 käyttäjiä oli jo 82 % vastaajista. 2019 WhatsAppia käytti 89 %
        vastaajista.
      </ResultSection>

      <ResultSection
        imageSrc={someTuonutElämäänImg}
        imageAlt="Sosiaalisen median merkityksellisyys"
        title="Sosiaalisen median merkityksellisyys"
        order={2}
      >
        Viestintä on nuorille sosiaalista toimintaa, joka vahvistaa
        yhteenkuuluvuuden tunnetta ja on tuottanut suurelle osalle vastaajista
        selkeää iloa ja lisävarmuutta. Ilahduttavasti nyt lähes 52 % on kokenut
        vahvasti ilon tunteita. Vuonna 2019 lukema oli vajaa 49 %. Useat
        vastaajista ovat saaneet apua erinäisiin ongelmiin ja kokeneet
        onnistumisen tunteita. Vastaajille sosiaalinen media on ainakin osittain
        tuottanut myös surua. Hieman alle 13 % ei ole saanut minkäänlaisia surun
        tunteita sosiaalisen median kautta. Vastaajien kohdalla vajaa kolmannes
        on löytänyt seurustelukumppanin sosiaalisen median kautta, tai
        sosiaalinen media on myötävaikuttanut asiaan.
      </ResultSection>

      <ResultSection
        imageSrc={mihinMedioihinLuotetaanImg}
        imageAlt="Mihin medioihin luotetaan?"
        title={"Mihin medioihin luotetaan?"}
        order={1}
      >
        Verkkomedioiden luotettavuutta kysyttiin nyt kolmannen kerran. Kysyimme,
        mitä verkkomedioita nuoret pitävät luotettavana ja hyvän sisällön
        lähteinä. Vastaajista jopa 88 % pitää Yle.fi:tä luotettavana. Seuraavana
        ovat maakuntien ja eri alueiden paikallislehdet – tai niiden
        verkkosisällöt (73 %). Wikipediaa pitää luotettavana edelleen vain 44 %
        vastaajista. Wikipedian prosenttiosuus on ollut sama kolmena
        kyselytoteutuksena peräkkäin. Kaupallisten medioiden prosenttiosuus on
        hieman noussut vuoden 2019 kyselystä. MTV 3 on suomessa panostanut
        varsin paljon uutis- ja ajankohtaistuotantoon.
      </ResultSection>
    </section>

    <Contact />
  </Layout>
)
