import { Link } from "gatsby"
import React from "react"

export default () => (
  <section className="slice slice-lg bg-section-secondary">
    <div className="container mb-4">
      <div className="text-center">
        <h2>Ota yhteyttä</h2>
        <div className="fluid-paragraph mt-4">
          <p className="lead text-muted lh-180">
            Haluatko lisätietoja tai kiinnostuitko palveluistamme? Jätä meille
            tietosi, niin olemme sinuun yhteydessä.
          </p>
          <Link
            to="/yhteystiedot"
            className="btn btn-primary rounded-pill hover-scale-110 mt-4"
          >
            Ota yhteyttä
          </Link>
        </div>
      </div>
    </div>
  </section>
)
