import React from "react"
import clsx from "clsx"
import ScrollAnim from "react-animate-on-scroll"

const ResultSection = ({ imageSrc, imageAlt, children, order, title }) => {
  const firstPartClass = order == 1 ? "order-lg-1" : "order-lg-2"
  const secondPartClass = order == 1 ? "order-lg-2" : "order-lg-1"

  return (
    <div className={"section-process-step"}>
      <div className="container">
        <div className="row row-grid align-items-center justify-content-between">
          <div className={clsx("col-xl-5 col-lg-6", firstPartClass)}>
            <div className="pr-md-4">
              <h3 className="mt-0 mt-lg-4">{title}</h3>
              <p className="lead my-4">{children}</p>
            </div>
          </div>
          <div className={clsx("col-lg-5", secondPartClass)}>
            <ScrollAnim
              animateIn="fadeIn"
              duration="0.5"
              animateOnce="true"
              offset="120"
            >
              <img
                alt={imageAlt}
                src={imageSrc}
                className="img-center img-fluid rounded-lg shadow-lg"
              />
            </ScrollAnim>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ResultSection
